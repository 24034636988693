import { DAY_MILLISECONDS, nextAvailableDate } from '../classes/timestamps';
import { multidayToSingleDays } from '../classes/splitdates';
export class MultiDaySummary {
    constructor(summaries, closingTimes) {
        this.summaries = summaries;
        this.closingTimes = closingTimes;
    }
    getEmptySpot(from, to, numberOfSlots) {
        return this.openSpots(from, to, numberOfSlots)[0];
    }
    openSpots(from, to, numberOfSpots) {
        const inClosingTimes = this.closingTimes.some(times => {
            const fromAfterFrom = nextAvailableDate(from, times.fromDay, times.fromHour, times.fromMinute, false);
            const toBeforeTo = nextAvailableDate(from, times.toDay, times.toHour, times.toMinute, true);
            const longerThenAWeek = (fromAfterFrom.getTime() - toBeforeTo.getTime()) > 7 * DAY_MILLISECONDS;
            return fromAfterFrom.getTime() < to.getTime() || from.getTime() < toBeforeTo.getTime() || longerThenAWeek;
        });
        if (inClosingTimes)
            return [];
        const dates = multidayToSingleDays(from, to);
        const allSpots = new Set();
        for (let spotNum = 0; spotNum < numberOfSpots; ++spotNum) {
            allSpots.add(spotNum);
        }
        dates.forEach(day => {
            this.summaries.forEach(summary => {
                const spots = summary.availableSpots(day.from, day.to, numberOfSpots);
                const toDelete = [];
                allSpots.forEach(spot => {
                    if (!spots.includes(spot))
                        toDelete.push(spot);
                });
                toDelete.forEach(spot => allSpots.delete(spot));
            });
        });
        const availableSpots = [...allSpots];
        availableSpots.sort((a, b) => a - b);
        return availableSpots;
    }
    numOpenSpots(from, to, numberOfSlots) {
        return this.openSpots(from, to, numberOfSlots).length;
    }
    // eslint-disable-next-line complexity
    getOpenTakeawaySpots(date, isSuperUser = false) {
        const intervalInMinutes = 5;
        const slots = [[18, 0], [18, 30], [19, 0], [17, 30]];
        const moments = [];
        let index = 0;
        while ((moments.length === 0 || isSuperUser) && index < slots.length) {
            const slot = slots[index];
            index++;
            if (slot === undefined)
                continue;
            const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), slot[0], slot[1]);
            const hour = slot[1] === 0 ? slot[0] : slot[0] + 1;
            const minute = slot[1] === 0 ? 30 : 0;
            const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute);
            for (let timestamp = start.getTime(); timestamp <= end.getTime(); timestamp += intervalInMinutes * 60 * 1000) {
                const date = new Date(timestamp);
                const taken = Object.values(this.summaries).some(summary => !summary.isTakeAwaySlotAvailable(date));
                if (!taken) {
                    moments.push(timestamp);
                }
            }
        }
        return moments.sort((a, b) => a - b);
    }
    getBreakfastTiming(date) {
        const moments = [];
        const breakfastTiming = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0);
        moments.push(breakfastTiming.getTime());
        return moments;
    }
}
