import { defineModule } from 'direct-vuex';
import { moduleActionContext, moduleGetterContext } from '..';
const clockModule = defineModule({
    namespaced: true,
    state: {
        now: 0
    },
    getters: {},
    actions: {},
    mutations: {
        update(state) {
            const d = new Date();
            state.now = Math.floor(d.getTime() / 1000);
        }
    }
});
export default clockModule;
export const clockGetterContext = (args) => moduleGetterContext(args, clockModule);
export const clockActionContext = (context) => moduleActionContext(context, clockModule);
