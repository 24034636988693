import { defineComponent } from 'vue';
import NavbarTop from '@/components/Navbar';
import Footer from '@/components/Footer';
export default defineComponent({
    name: 'App',
    components: {
        NavbarTop,
        Footer
    },
    computed: {
        isFullScreen() {
            var _a;
            return (_a = this.$route.query.fullscreen === 'true') !== null && _a !== void 0 ? _a : false;
        }
    },
    mounted() {
        this.$store.direct.dispatch.locations.subscribe();
    },
    unmounted() {
        this.$store.direct.dispatch.locations.unsubscribe();
    }
});
