import EditProfileForm from '@/components/EditProfile';
import ModalBox from '@/components/ModalBox';
import useAuth from '@/compositions/auth';
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'EditProfileButton',
    components: {
        EditProfileForm,
        ModalBox
    },
    props: {
        buttonType: {
            type: String,
            required: true,
            validator: (value) => ['image', 'text'].includes(value)
        }
    },
    setup() {
        return { ...useAuth() };
    },
    data: () => ({
        editProfile: false,
        userProfile: null
    }),
    computed: {
        image() {
            return require('@/assets/editprofile.svg');
        }
    },
    watch: {
        userinfo: {
            handler(newVal) {
                this.userProfile = cloneDeep(newVal);
            },
            immediate: true
        }
    },
    methods: {
        profileUpdated() {
            this.editProfile = false;
            if (this.userProfile === null)
                return;
            this.$store.direct.dispatch.auth.updateUserInfo(this.userProfile);
        }
    }
});
