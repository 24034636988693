import EditProfileButton from './EditProfileButton';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'NavbarTop',
    components: {
        EditProfileButton
    },
    computed: {
        isSuperUser() {
            return this.$store.direct.getters.auth.isSuperUser;
        },
        isLoggedIn() {
            return this.$store.direct.getters.auth.isLoggedIn;
        },
        uid() {
            var _a, _b;
            return (_b = (_a = this.$store.direct.state.auth.user) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : null;
        },
        userInfo() {
            return this.$store.direct.state.auth.userInfo;
        }
    },
    methods: {
        logout() {
            this.$store.direct.dispatch.auth.logout();
            this.$router.push({ name: 'Home' });
        },
        loginWithGoogle() {
            this.$store.direct.dispatch.auth.loginWithGoogle().then(() => {
                console.log('login successfull');
            }).catch(error => {
                console.error('could not log in', error);
            });
        },
        loginWithFacebook() {
            this.$store.direct.dispatch.auth.loginWithFacebook().then(() => {
                console.log('login successfull');
            }).catch(error => {
                console.error('could not log in', error);
            });
        }
    }
});
