export var CollectionNames;
(function (CollectionNames) {
    CollectionNames["CUSTOMERS"] = "customers";
    CollectionNames["LOCATIONS"] = "locations";
    CollectionNames["DAYSUMMARY"] = "daysummary";
    CollectionNames["PRODUCTS"] = "products";
    CollectionNames["PRODUCTGROUPS"] = "productgroup";
    CollectionNames["RESERVATIONS"] = "reservations";
    CollectionNames["TAKEAWAY"] = "takeaway";
})(CollectionNames || (CollectionNames = {}));
