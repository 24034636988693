import { Product } from './products';
export class TakeAway {
    constructor(reservationId, customerId, pickUpDate, location, comment = '') {
        this.products = [];
        this.reservationId = reservationId;
        this.customerId = customerId;
        this.pickUpDate = pickUpDate;
        this.location = location;
        this.creationDate = new Date();
        this.comment = comment;
    }
    // eslint-disable-next-line complexity
    load(data) {
        if (typeof data.reservationId === 'string')
            this.reservationId = data.reservationId;
        if (typeof data.customerId === 'string')
            this.customerId = data.customerId;
        if (typeof data.location === 'string')
            this.location = data.location;
        if (data.pickUpDate !== undefined)
            this.pickUpDate = new Date(data.pickUpDate.seconds * 1000);
        if (data.creationDate !== undefined)
            this.creationDate = new Date(data.creationDate.seconds * 1000);
        if (typeof data.comment === 'string')
            this.comment = data.comment;
        if (Array.isArray(data.products)) {
            this.products = [];
            for (const productData of data.products) {
                const product = new Product(productData.name, productData.price, productData.location);
                product.load(productData);
                this.products.push(product);
            }
        }
    }
    dump() {
        const data = { ...this };
        data.products = [];
        this.products.forEach(product => {
            data.products.push(product.dump());
        });
        return data;
    }
    totalPrice() {
        return this.products.map(product => product.price).reduce((total, price) => total + price, 0);
    }
}
