import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Bugsnag from '@bugsnag/js';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueGtag from 'vue-gtag-next';
if (process.env.NODE_ENV !== 'development') {
    store.dispatch.debug.enable();
}
const bugsnagVue = Bugsnag.getPlugin('vue');
const toastOptions = {
    position: POSITION.TOP_CENTER
};
const app = createApp(App);
app.use(store.original).use(bugsnagVue).use(Toast, toastOptions).use(router).use(VueGtag, {
    property: {
        id: 'UA-166074263-1',
        params: {
            send_page_view: true
        }
    },
    router
});
app.mount('#app');
if (process.env.NODE_ENV === 'development') {
    console.log('Enable development tools');
    app.config.performance = true;
}
