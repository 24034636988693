import { defineModule } from 'direct-vuex';
import { moduleActionContext, moduleGetterContext } from '..';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
const debugModule = defineModule({
    namespaced: true,
    state: {
        bugsnagEnabled: false
    },
    getters: {},
    actions: {
        updateUserMetadata(context, { user, uid }) {
            const { state } = debugActionContext(context);
            if (!state.bugsnagEnabled)
                return;
            Bugsnag.addMetadata('user', {
                uid,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email
            });
        },
        enable(context) {
            const { state, commit } = debugActionContext(context);
            if (state.bugsnagEnabled) {
                console.warn('Bugsnag already activated');
                return;
            }
            Bugsnag.start({
                apiKey: 'b64aa5f3eb411d34e9d2afa58bc039f8',
                plugins: [new BugsnagPluginVue()]
            });
            console.error = (data) => {
                Bugsnag.notify(data);
                console.log('Logged to Bugsnag:', data);
            };
            commit.setEnabledState(true);
        }
    },
    mutations: {
        setEnabledState(state, enabled) {
            state.bugsnagEnabled = enabled;
        }
    }
});
export default debugModule;
export const debugGetterContext = (args) => moduleGetterContext(args, debugModule);
export const debugActionContext = (context) => moduleActionContext(context, debugModule);
