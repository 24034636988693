import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import store, { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db, auth } from '@/classes/firebase';
import firebase from 'firebase';
import { Customer, CustomerType } from '@/types/customers';
import { CollectionNames } from '@/types/collectionnames';
const createUserDocIfNecessary = async (user) => {
    const result = await db.collection(CollectionNames.CUSTOMERS).doc(user.uid).get();
    if (result.exists)
        return;
    await db.collection(CollectionNames.CUSTOMERS).doc(user.uid).set({
        firstname: user.displayName,
        email: user.email,
        phone: user.phoneNumber,
        avatar: user.photoURL,
        created: new Date(),
        type: CustomerType.User
    });
};
const setReservationFilter = (isSuperUser, uid) => {
    const today = new Date();
    const threeDaysAgo = new Date(today.setDate(today.getDate() - 3));
    const filter = {
        fromDate: threeDaysAgo,
        toDate: null,
        field: isSuperUser ? null : 'uid',
        value: uid,
        sorted: 'fromDate'
    };
    store.dispatch.reservations.applyFilter(filter);
};
const setTakeAwayFilter = (isSuperUser, uid) => {
    const today = new Date();
    const threeDaysAgo = new Date(today.setDate(today.getDate() - 3));
    const filter = {
        fromDate: threeDaysAgo,
        toDate: null,
        field: isSuperUser ? null : 'uid',
        value: uid
    };
    store.dispatch.takeaway.applyFilter(filter);
};
const authModule = defineModule({
    namespaced: true,
    state: {
        user: null,
        userInfo: null
    },
    getters: {
        isLoggedIn(state) {
            return state.user !== null;
        },
        registrationComplete(state) {
            if (state.userInfo === null)
                return false;
            return state.userInfo.licenceplate !== null;
        },
        isSuperUser(state) {
            if (state.userInfo === null)
                return false;
            return state.userInfo.type === CustomerType.SuperUser;
        }
    },
    actions: {
        subscribe(context) {
            const { commit } = authActionContext(context);
            auth.onAuthStateChanged(user => {
                commit.setUser(user);
            });
        },
        unsubscribe(context) {
            var _a, _b;
            const { state } = authActionContext(context);
            if (state.user === null)
                return;
            const countSubscriptions = (_b = (_a = storehelper.subscriptions[state.user.uid]) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0;
            if (countSubscriptions > 0) {
                storehelper.unsubscribe(state.user.uid);
            }
        },
        logout(_context) {
            auth.signOut();
        },
        async loginWithGoogle(_context) {
            const provider = new firebase.auth.GoogleAuthProvider();
            const result = await auth.signInWithPopup(provider);
            if (result.user !== null)
                await createUserDocIfNecessary(result.user);
        },
        async loginWithFacebook(_context) {
            const provider = new firebase.auth.FacebookAuthProvider();
            const result = await auth.signInWithPopup(provider);
            if (result.user !== null)
                await createUserDocIfNecessary(result.user);
        },
        async updateUserInfo(context, userInfo) {
            const { state } = authActionContext(context);
            if (state.user === null)
                throw new Error('Cannot update user info if you are not logged in');
            await db.collection(CollectionNames.CUSTOMERS).doc(state.user.uid).set({ ...userInfo });
        }
    },
    mutations: {
        updateUserInfo(state, snap) {
            var _a, _b, _c, _d;
            if (!snap.exists) {
                state.userInfo = null;
                return;
            }
            const beforeType = (_a = state.userInfo) === null || _a === void 0 ? void 0 : _a.type;
            const data = snap.data();
            state.userInfo = new Customer(data);
            store.dispatch.debug.updateUserMetadata({ uid: (_c = (_b = state.user) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : 'not-logged-in', user: state.userInfo });
            const afterType = (_d = state.userInfo) === null || _d === void 0 ? void 0 : _d.type;
            if (beforeType !== afterType && state.user !== null) {
                setReservationFilter(afterType === 'superuser', state.user.uid);
                setTakeAwayFilter(afterType === 'superuser', state.user.uid);
            }
        },
        setUser(state, user) {
            var _a, _b;
            state.user = user;
            const subscriptionKey = 'userinfo';
            const countSubscriptions = (_b = (_a = storehelper.subscriptions[subscriptionKey]) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0;
            if (countSubscriptions > 0) {
                storehelper.unsubscribe(subscriptionKey);
            }
            if (user !== null) {
                const snapper = {
                    doc: db.collection(CollectionNames.CUSTOMERS).doc(user.uid),
                    mutation: store.commit.auth.updateUserInfo,
                    type: FirestoreSnapperType.Document
                };
                storehelper.subscribe(subscriptionKey, snapper);
            }
            else {
                state.userInfo = null;
            }
        }
    }
});
export default authModule;
export const authGetterContext = (args) => moduleGetterContext(args, authModule);
export const authActionContext = (context) => moduleActionContext(context, authModule);
