import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/database';
import { FUNCTION_REGION } from '@/types/globals';
const firebaseConfig = {
    apiKey: 'AIzaSyAxVejlML2WcH6WA6ddSe7laZcrIXaHWLc',
    authDomain: 'camperlocatie-125e3.firebaseapp.com',
    projectId: 'camperlocatie-125e3',
    storageBucket: 'camperlocatie-125e3.appspot.com',
    // messagingSenderId: "184052670906",
    appId: '1:184052670906:web:9edf89ffa34a77d45f7d83',
    measurementId: 'G-E58C6BW2RF'
};
const app = firebase.initializeApp(firebaseConfig);
let db = firebase.firestore(app);
const auth = firebase.auth();
const storage = firebase.storage();
const functions = app.functions(FUNCTION_REGION);
let cleanupConnection = async () => { };
let clearDB = async () => { };
/* istanbul ignore else */
if (process.env.NODE_ENV === 'test') {
    process.env.FIRESTORE_EMULATOR_HOST = 'localhost:9888';
    const firebase = require('@firebase/testing');
    const projectId = 'beatout-test';
    db = firebase.initializeAdminApp({ projectId }).firestore();
    cleanupConnection = async () => {
        await Promise.all(firebase.apps().map((app) => app.delete()));
    };
    clearDB = async () => {
        await firebase.clearFirestoreData({ projectId });
    };
}
export { db, storage, auth, functions, cleanupConnection, clearDB };
