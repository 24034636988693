import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import store, { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db, functions } from '@/classes/firebase';
import { CollectionNames } from '@/types/collectionnames';
import { TakeAway } from '@/types/takeaway';
const SUBSCRIPTION_KEY = 'takeaway';
const makeQuery = async (db, filter) => {
    let query = db.collection(CollectionNames.TAKEAWAY).where('location', '==', store.state.locations.currentLocationId);
    query = query.where('location', '==', store.state.locations.currentLocationId);
    if (filter.field !== null) {
        let customerId = '';
        if (filter.field === 'uid') {
            customerId = filter.value;
        }
        else {
            try {
                const doc = await db.collection(CollectionNames.CUSTOMERS).where(filter.field, '==', filter.value).get();
                doc.forEach(d => { customerId = d.id; });
            }
            catch (error) {
                console.log('No permission', error);
            }
        }
        query = query.where('customerId', '==', customerId);
    }
    if (filter.fromDate !== null) {
        query = query.where('pickUpDate', '>', filter.fromDate);
    }
    if (filter.toDate !== null) {
        query = query.where('pickUpDate', '<', filter.toDate);
    }
    return query;
};
const takeawayModule = defineModule({
    namespaced: true,
    state: {
        takeaway: {},
        filter: {
            field: 'uid',
            value: '',
            fromDate: null,
            toDate: null
        }
    },
    getters: {
        productCount(state) {
            const count = {};
            if (state.takeaway === undefined)
                return count;
            for (const [_key, takeaway] of Object.entries(state.takeaway)) {
                for (const [_keyProduct, product] of Object.entries(takeaway.products)) {
                    if (count[product.getKitchenname()] === undefined)
                        count[product.getKitchenname()] = 0;
                    count[product.getKitchenname()]++;
                }
            }
            return Object.keys(count)
                .sort()
                .reduce((acc, key) => ({
                ...acc, [key]: count[key]
            }), {});
        }
    },
    actions: {
        async subscribe(context) {
            const { commit, state } = takeawayActionContext(context);
            if (commit.update === undefined)
                return;
            const snapper = {
                query: await makeQuery(db, state.filter),
                mutation: commit.update,
                type: FirestoreSnapperType.Query
            };
            return await storehelper.subscribe(SUBSCRIPTION_KEY, snapper);
        },
        unsubscribe(_context) {
            return storehelper.unsubscribe(SUBSCRIPTION_KEY);
        },
        async applyFilter(context, filter) {
            const { commit, dispatch } = takeawayActionContext(context);
            commit.updateFilter(filter);
            dispatch.refreshQuery();
        },
        async refreshQuery(context) {
            const { state } = takeawayActionContext(context);
            const query = await makeQuery(db, state.filter);
            if (storehelper.exists(SUBSCRIPTION_KEY)) {
                storehelper.updateQuery(SUBSCRIPTION_KEY, query);
            }
        },
        async add(_context, takeaway) {
            const f = functions;
            const functionMakeTakeAway = f.httpsCallable('makeTakeAway');
            const result = await functionMakeTakeAway({
                takeaway: takeaway.dump(),
                pickupTimestamp: takeaway.pickUpDate.getTime()
            });
            console.log('TAKEAWAY', result);
            if (result.data.status === 'error') {
                throw new Error('TakeAway error: ' + result.data.message);
            }
            return result.data.id;
        },
        async delete(_context, takeawayId) {
            await db.collection(CollectionNames.TAKEAWAY).doc(takeawayId).delete();
        },
        async update(context, { id, update }) {
            const { state } = takeawayActionContext(context);
            const currentTakeAway = state.takeaway[id];
            if (currentTakeAway === undefined)
                throw new Error('Takeaway does not exists');
            if (update.reservationId !== undefined)
                throw new Error('Cannot adjust the reservationId of a takeaway');
            if (update.location !== undefined)
                throw new Error('Cannot adjust the location of a reservation');
            if (update.pickUpDate !== undefined && update.pickUpDate.getTime() !== currentTakeAway.pickUpDate.getTime()) {
                const f = functions;
                const functionEditTakeAway = f.httpsCallable('editTakeAway');
                const result = await functionEditTakeAway({
                    takeawayId: id,
                    pickupTimestamp: update.pickUpDate.getTime()
                });
                if (result.data.status === 'error')
                    throw new Error(result.data.message);
            }
            await db.collection(CollectionNames.TAKEAWAY).doc(id).set(update, { merge: true });
        }
    },
    mutations: {
        update(state, snap) {
            state.takeaway = {};
            snap.docs.forEach(doc => {
                const data = doc.data();
                const takeaway = new TakeAway(data.reservationId, data.customerId, data.pickUpDate, data.location, data.comment);
                takeaway.load(data);
                state.takeaway[doc.id] = takeaway;
            });
        },
        updateFilter(state, filter) {
            state.filter = filter;
        }
    }
});
export default takeawayModule;
export const takeawayGetterContext = (args) => moduleGetterContext(args, takeawayModule);
export const takeawayActionContext = (context) => moduleActionContext(context, takeawayModule);
