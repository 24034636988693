import { FirestoreSnapperType } from './types';
class StoreHelper {
    constructor() {
        this.subscriptions = {};
    }
    __getOrCreateSubscription(key) {
        if (this.subscriptions[key] === undefined) {
            this.subscriptions[key] = {
                count: 0,
                destroy: null,
                snapper: null
            };
        }
        const subscription = this.subscriptions[key];
        if (subscription === undefined) {
            throw new Error('Something went wrong in subscribe, please check');
        }
        return subscription;
    }
    async subscribe(key, snapper) {
        const subscription = this.__getOrCreateSubscription(key);
        if (subscription.count === 0) {
            if (snapper.type === FirestoreSnapperType.Query) {
                const snap = snapper;
                subscription.destroy = snap.query.onSnapshot(data => snap.mutation(data), error => {
                    throw new Error(`Error subscribing ${key} : ${error}`);
                });
            }
            else if (snapper.type === FirestoreSnapperType.Document) {
                const snap = snapper;
                subscription.destroy = snap.doc.onSnapshot(data => snap.mutation(data), error => {
                    throw new Error(`Error subscribing ${key} : ${error}`);
                });
            }
            subscription.snapper = snapper;
        }
        subscription.count += 1;
        return { key, count: subscription.count };
    }
    unsubscribe(key, mutation) {
        const subscription = this.subscriptions[key];
        if (subscription === undefined || subscription.count === 0) {
            // throw Error(`You should not unsubscribe if you have not subscribed yet ${key}`)
            console.warn(`You should not unsubscribe if you have not subscribed yet ${key}`);
            return;
        }
        subscription.count -= 1;
        const count = subscription.count;
        const destroyFunction = subscription.destroy;
        if (count === 0 && destroyFunction !== null) {
            destroyFunction();
            if (mutation) {
                mutation();
            }
            if (this.subscriptions[key] !== undefined)
                delete this.subscriptions[key];
        }
        return { key, count };
    }
    updateQuery(key, query) {
        var _a;
        const subscription = this.subscriptions[key];
        if (subscription === undefined) {
            throw new Error('Cannot update query of non-existing subscription');
        }
        if (((_a = subscription.snapper) === null || _a === void 0 ? void 0 : _a.type) !== FirestoreSnapperType.Query) {
            throw new Error('Can only update query subscriptions');
        }
        if (subscription.destroy === null) {
            throw new Error('Subscription was not properly created');
        }
        subscription.destroy();
        const snapper = subscription.snapper;
        subscription.destroy = query.onSnapshot(data => snapper.mutation(data), error => {
            throw new Error(`Error subscribing ${key} : ${error}`);
        });
    }
    exists(key) {
        const subscription = this.subscriptions[key];
        return subscription !== undefined && subscription.count > 0;
    }
    runningKeys(prefix = '') {
        return Object.keys(this.subscriptions).filter(key => { var _a; return key.startsWith(prefix) && ((_a = this.subscriptions[key]) === null || _a === void 0 ? void 0 : _a.count) !== 0; });
    }
}
export default StoreHelper;
