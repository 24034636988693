import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import store, { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db } from '@/classes/firebase';
import { CamperLocation } from '@/types/locations';
import { CollectionNames } from '@/types/collectionnames';
import { DEFAULT_LOCATION } from '@/types/globals';
const locationsModule = defineModule({
    namespaced: true,
    state: {
        currentLocationId: DEFAULT_LOCATION,
        locations: {}
    },
    getters: {
        currentLocation(state) {
            return state.locations[state.currentLocationId];
        }
    },
    actions: {
        async subscribe(context) {
            const { commit } = locationsActionContext(context);
            if (commit.update === undefined)
                return;
            const subscriptionKey = 'locations';
            const snapper = {
                query: db.collection(CollectionNames.LOCATIONS),
                mutation: commit.update,
                type: FirestoreSnapperType.Query
            };
            return await storehelper.subscribe(subscriptionKey, snapper);
        },
        unsubscribe(_context) {
            storehelper.unsubscribe('locations');
        },
        async setCurrentLocation(context, locationId) {
            const { commit } = locationsActionContext(context);
            commit.setCurrentLocation(locationId);
            await store.dispatch.reservations.refreshQuery();
            await store.dispatch.daysummaries.refreshQuery();
            await store.dispatch.products.refreshQuery();
            await store.dispatch.takeaway.refreshQuery();
        },
        async add(_context, location) {
            const doc = await db.collection(CollectionNames.LOCATIONS).add(location.export());
            return doc.id;
        }
    },
    mutations: {
        update(state, snap) {
            state.locations = {};
            snap.docs.forEach(doc => {
                state.locations[doc.id] = new CamperLocation(doc.data());
            });
        },
        setCurrentLocation(state, id) {
            state.currentLocationId = id;
        }
    }
});
export default locationsModule;
export const locationsGetterContext = (args) => moduleGetterContext(args, locationsModule);
export const locationsActionContext = (context) => moduleActionContext(context, locationsModule);
