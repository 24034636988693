import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
const useAuth = () => {
    onMounted(async () => {
        await store.dispatch.auth.subscribe();
    });
    onBeforeUnmount(async () => {
        await store.dispatch.auth.unsubscribe();
    });
    const userinfo = computed(() => {
        return store.state.auth.userInfo;
    });
    const uid = computed(() => {
        var _a;
        return (_a = store.state.auth.user) === null || _a === void 0 ? void 0 : _a.uid;
    });
    const registrationComplete = computed(() => {
        return store.getters.auth.registrationComplete;
    });
    const isSuperUser = computed(() => {
        return store.getters.auth.isSuperUser;
    });
    return {
        userinfo,
        uid,
        isSuperUser,
        registrationComplete
    };
};
export default useAuth;
