export class TakeAwaySummary {
    constructor(date, takeaway) {
        this.date = date;
        this.takeaway = takeaway;
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('TakeAwaySummary: data is not an object');
        if (data.date !== undefined)
            this.date = new Date(data.date.seconds * 1000);
        if (typeof data.takeaway === 'string')
            this.takeaway = data.takeaway;
    }
    // eslint-disable-next-line sonarjs/no-identical-functions
    export() {
        return {
            ...this
        };
    }
}
