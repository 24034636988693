import { defineComponent } from 'vue';
export default defineComponent({
    name: 'EditProfileForm',
    props: {
        userProfile: {
            type: Object,
            required: true
        }
    }
});
