export class Product {
    constructor(name, price, location, description = '', group = '', kitchenname = '') {
        this.name = name;
        this.price = price;
        this.description = description;
        this.group = group;
        this.location = location;
        this.kitchenname = kitchenname;
    }
    load(data) {
        if (typeof data.name === 'string')
            this.name = data.name;
        if (typeof data.price === 'number')
            this.price = data.price;
        if (typeof data.description === 'string')
            this.description = data.description;
        if (typeof data.group === 'string')
            this.group = data.group;
        if (typeof data.location === 'string')
            this.location = data.location;
        if (typeof data.kitchenname === 'string')
            this.kitchenname = data.kitchenname;
    }
    dump() {
        return { ...this };
    }
    getKitchenname() {
        return this.kitchenname !== '' ? this.kitchenname : this.name;
    }
}
export class ProductGroup {
    constructor() {
        this.fromHour = 0;
        this.toHour = 24;
    }
    load(data) {
        if (typeof data !== 'object')
            return;
        if (typeof data.fromHour === 'number')
            this.fromHour = data.fromHour;
        if (typeof data.toHour === 'number')
            this.toHour = data.toHour;
    }
    dump() {
        return { ...this };
    }
    withinTimeslot(hour) {
        return this.fromHour <= hour && hour <= this.toHour;
    }
}
