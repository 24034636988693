export var CustomerType;
(function (CustomerType) {
    CustomerType["User"] = "user";
    CustomerType["SuperUser"] = "superuser";
})(CustomerType || (CustomerType = {}));
export class Customer {
    constructor(data = undefined) {
        this.email = null;
        this.firstname = '';
        this.lastname = '';
        this.phone = null;
        this.licenceplate = null;
        this.type = CustomerType.User;
        if (typeof data !== 'object')
            return;
        if (typeof data.email === 'string')
            this.email = data.email;
        if (typeof data.firstname === 'string')
            this.firstname = data.firstname;
        if (typeof data.lastname === 'string')
            this.lastname = data.lastname;
        if (typeof data.phone === 'string')
            this.phone = data.phone;
        if (typeof data.licenceplate === 'string')
            this.licenceplate = data.licenceplate;
        if (typeof data.type === 'string')
            this.type = data.type;
    }
    dump() {
        return { ...this };
    }
}
