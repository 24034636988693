import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import store, { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db } from '@/classes/firebase';
import { CollectionNames } from '@/types/collectionnames';
import { DaySummary } from '@/types/daysummary';
import { DAY_MILLISECONDS, midnightOfDate } from '@/classes/timestamps';
import { MultiDaySummary } from '@/types/multidaysummary';
const SUBSCRIPTION_KEY = 'daysummaries';
const today = midnightOfDate(new Date());
const makeQuery = (db, filter) => {
    return db.collection(CollectionNames.DAYSUMMARY)
        .where('location', '==', store.state.locations.currentLocationId)
        .where('startDate', '>=', filter.fromDate)
        .where('startDate', '<=', filter.toDate);
};
const daySummariesModule = defineModule({
    namespaced: true,
    state: {
        daySummary: {},
        filter: {
            fromDate: today,
            toDate: new Date(today.getTime() + DAY_MILLISECONDS * 7)
        }
    },
    getters: {
        multidaySummary(state) {
            var _a;
            const location = store.getters.locations.currentLocation;
            return new MultiDaySummary(Object.values(state.daySummary), (_a = location === null || location === void 0 ? void 0 : location.closingTimes) !== null && _a !== void 0 ? _a : []);
        }
    },
    actions: {
        async subscribe(context) {
            const { commit, state } = daySummariesActionContext(context);
            if (commit.update === undefined)
                return;
            const snapper = {
                query: makeQuery(db, state.filter),
                mutation: commit.update,
                type: FirestoreSnapperType.Query
            };
            return await storehelper.subscribe(SUBSCRIPTION_KEY, snapper);
        },
        unsubscribe(_context) {
            storehelper.unsubscribe(SUBSCRIPTION_KEY);
        },
        async applyFilter(context, filter) {
            const { commit, dispatch } = daySummariesActionContext(context);
            commit.updateFilter(filter);
            await dispatch.refreshQuery();
        },
        async refreshQuery(context) {
            const { state } = daySummariesActionContext(context);
            if (storehelper.exists(SUBSCRIPTION_KEY)) {
                storehelper.updateQuery(SUBSCRIPTION_KEY, makeQuery(db, state.filter));
            }
        }
    },
    mutations: {
        update(state, snap) {
            state.daySummary = {};
            snap.docs.forEach(doc => {
                const daySummary = new DaySummary();
                daySummary.load(doc.data());
                state.daySummary[doc.id] = daySummary;
            });
        },
        updateFilter(state, filter) {
            state.filter = filter;
        }
    }
});
export default daySummariesModule;
export const daySummariesGetterContext = (args) => moduleGetterContext(args, daySummariesModule);
export const daySummariesActionContext = (context) => moduleActionContext(context, daySummariesModule);
