import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import store, { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db, functions } from '@/classes/firebase';
import { Reservation } from '@/types/reservations';
import { CollectionNames } from '@/types/collectionnames';
const SUBSCRIPTION_KEY = 'reservations';
const createQuery = async (db, filter) => {
    let query = db.collection(CollectionNames.RESERVATIONS);
    query = query.where('location', '==', store.state.locations.currentLocationId);
    if (filter.field === 'accepted') {
        query = query.where('accepted', '==', filter.value === 'true');
    }
    else if (filter.field === 'arrived') {
        query = query.where('arrived', '==', filter.value === 'true');
    }
    else if (filter.field !== null) {
        let customerId = '';
        if (filter.field === 'uid') {
            customerId = filter.value;
        }
        else {
            try {
                const doc = await db.collection(CollectionNames.CUSTOMERS).where(filter.field, '==', filter.value).get();
                doc.forEach(d => { customerId = d.id; });
            }
            catch (error) {
                console.log('No permission', error);
            }
        }
        query = query.where('customerid', '==', customerId);
    }
    if (filter.fromDate !== null)
        query = query.where('fromDate', '>', filter.fromDate);
    if (filter.toDate !== null)
        query = query.where('fromDate', '<', filter.toDate);
    return query.orderBy(filter.sorted);
};
const reservationsModule = defineModule({
    namespaced: true,
    state: {
        filter: {
            field: 'uid',
            value: '',
            fromDate: new Date(),
            toDate: new Date(new Date().getTime() + 7 * 3600 * 24 * 1000),
            sorted: 'fromDate'
        },
        reservations: {}
    },
    getters: {
        mostRecentReservationId(state) {
            let newestId = undefined;
            let newestDate = 0;
            Object.entries(state.reservations).forEach(([id, reservation]) => {
                if (reservation.__fromTimestamp > newestDate) {
                    newestDate = reservation.__fromTimestamp;
                    newestId = id;
                }
            });
            return newestId;
        },
        mostRecentReservation(state) {
            if (store.getters.reservations.mostRecentReservationId === undefined)
                return undefined;
            return state.reservations[store.getters.reservations.mostRecentReservationId];
        }
    },
    actions: {
        async applyFilter(context, filter) {
            // do checks that the filter is correct
            const { commit, dispatch } = reservationsActionContext(context);
            commit.setFilter(filter);
            await dispatch.refreshQuery();
        },
        async refreshQuery(context) {
            const { state } = reservationsActionContext(context);
            const query = await createQuery(db, state.filter);
            if (storehelper.exists(SUBSCRIPTION_KEY)) {
                storehelper.updateQuery(SUBSCRIPTION_KEY, query);
            }
        },
        async subscribe(context) {
            const { commit, state } = reservationsActionContext(context);
            if (commit.update === undefined)
                return;
            const query = await createQuery(db, state.filter);
            const snapper = {
                query,
                mutation: commit.update,
                type: FirestoreSnapperType.Query
            };
            return await storehelper.subscribe(SUBSCRIPTION_KEY, snapper);
        },
        async unsubscribe(_context) {
            storehelper.unsubscribe(SUBSCRIPTION_KEY);
        },
        async makeReservation(_context, reservation) {
            const f = functions;
            const functionMakeReservation = f.httpsCallable('makeReservation');
            const result = await functionMakeReservation({ ...reservation });
            if (result.data.status === 'error') {
                throw new Error('Reservation error: ' + result.data.message);
            }
            return result.data.id;
        },
        async cancelReservation(_context, reservationId) {
            const f = functions;
            const functionCancelReservation = f.httpsCallable('cancelReservation');
            const result = await functionCancelReservation(reservationId);
            if (result.data.status === 'error') {
                throw new Error('Reservation error: ' + result.data.message);
            }
        },
        async acceptReservation(context, reservationId) {
            if (!store.getters.auth.isSuperUser) {
                throw new Error('Not authorized');
            }
            const { state } = reservationsActionContext(context);
            const reservation = state.reservations[reservationId];
            if (reservation === undefined)
                throw new Error('Cannot find the reservation');
            await db.collection(CollectionNames.RESERVATIONS).doc(reservationId).set({
                accepted: true
            }, { merge: true });
        },
        async update(_context, params) {
            const updates = params.updates;
            // FIXME: write tests for this function
            delete updates.__fromTimestamp;
            delete updates.__toTimestamp;
            delete updates.location;
            delete updates.spot;
            delete updates.spotFixed;
            await db.collection(CollectionNames.RESERVATIONS).doc(params.id).set(updates, { merge: true });
        }
    },
    mutations: {
        update(state, snap) {
            const foundKeys = [];
            snap.docs.forEach(doc => {
                foundKeys.push(doc.id);
                const reservation = new Reservation('unknown', new Date(), new Date(), 0, 'unknown');
                reservation.load(doc.data());
                state.reservations[doc.id] = reservation;
            });
            const existingKeys = Object.keys(state.reservations);
            existingKeys.forEach(key => {
                if (!foundKeys.includes(key)) {
                    delete state.reservations[key];
                }
            });
        },
        setFilter(state, filter) {
            state.filter = filter;
        }
    }
});
export default reservationsModule;
export const reservationsGetterContext = (args) => moduleGetterContext(args, reservationsModule);
export const reservationsActionContext = (context) => moduleActionContext(context, reservationsModule);
