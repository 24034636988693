import { DEFAULT_LOCATION } from './globals';
import { ReservationSummary } from './reservationsummary';
import { TakeAwaySummary } from './takeawaysummary';
class Spot {
    constructor(reservations) {
        this.reservations = {};
        this.reservations = reservations;
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('Spot: data is not an object');
        this.reservations = {};
        Object.entries(data.reservations).forEach(([id, resData]) => {
            const summary = new ReservationSummary(new Date(), new Date(), 'unknown');
            summary.load(resData);
            this.reservations[id] = summary;
        });
    }
    export() {
        const reservations = {};
        Object.entries(this.reservations).forEach(([id, reservation]) => reservations[id] = reservation.export());
        return {
            reservations
        };
    }
    length() {
        return Object.values(this.reservations).length;
    }
}
class DaySummary {
    constructor() {
        this.spots = [];
        this.takeaway = {};
        this.location = DEFAULT_LOCATION;
        this.__startTimestamp = 0;
    }
    load(data) {
        if (typeof data.location === 'string')
            this.location = data.location;
        if (typeof data.spots === 'object') {
            this.spots = [];
            Object.entries(data.spots).forEach(([index, spotData]) => {
                const spot = new Spot({});
                spot.load(spotData);
                this.spots[Number.parseInt(index)] = spot;
            });
        }
        if (typeof data.startDate === 'object') {
            this.__startTimestamp = data.startDate.seconds * 1000;
        }
        if (data.takeaway !== undefined) {
            this.takeaway = {};
            Object.entries(data.takeaway).forEach(([id, takeaway]) => {
                var _a, _b;
                const date = (_b = (_a = takeaway.date) === null || _a === void 0 ? void 0 : _a.seconds) !== null && _b !== void 0 ? _b : 0;
                this.takeaway[id] = new TakeAwaySummary(new Date(date * 1000), takeaway.takeaway);
            });
        }
    }
    export() {
        const takeawayExports = {};
        Object.entries(this.takeaway).forEach(([id, takeaway]) => {
            takeawayExports[id] = takeaway.export();
        });
        return {
            takeaway: takeawayExports,
            location: this.location,
            spots: this.spots.map(spot => spot.export()),
            startDate: this.startDate
        };
    }
    isSlotAvailable(index, from, to) {
        const spot = this.spots[index];
        if (spot === undefined)
            return true;
        return !Object.values(spot.reservations).some(reservation => {
            if (from < reservation.from && reservation.from < to)
                return true;
            if (from < reservation.to && reservation.to <= to)
                return true;
            return false;
        });
    }
    availableSpots(from, to, numberOfSlots) {
        const spots = [];
        for (let spotNum = 0; spotNum < numberOfSlots; ++spotNum) {
            if (this.isSlotAvailable(spotNum, from, to)) {
                spots.push(spotNum);
            }
        }
        return spots;
    }
    get startDate() {
        return new Date(this.__startTimestamp);
    }
    isTakeAwaySlotAvailable(date) {
        let momentFound = false;
        Object.values(this.takeaway).forEach(takeaway => {
            const diff = Math.abs(takeaway.date.getTime() - date.getTime());
            if (diff < 5 * 60 * 1000 - 1)
                momentFound = true;
        });
        return !momentFound;
    }
}
export { DaySummary, Spot, ReservationSummary };
