export class CamperLocation {
    constructor(data) {
        this.name = 'unknown location';
        this.closingTimes = [];
        this.openingHours = {
            fromHour: 13,
            fromMinute: 0,
            toHour: 20,
            toMinute: 0
        };
        this.numberOfSpots = 24;
        this.overCapacity = 10;
        if (typeof data !== 'object')
            return;
        if (typeof data.name === 'string')
            this.name = data.name;
        if (typeof data.closingTimes === 'object')
            this.closingTimes = data.closingTimes;
        if (typeof data.openingHours === 'object')
            this.openingHours = data.openingHours;
        if (typeof data.numberOfSpots === 'number')
            this.numberOfSpots = data.numberOfSpots;
        if (typeof data.overCapacity === 'number')
            this.overCapacity = data.overCapacity;
    }
    export() {
        return { ...this };
    }
    withinOpeningHours(date) {
        const fromSeconds = this.openingHours.fromHour * 3600 + this.openingHours.fromMinute * 60;
        const toSeconds = this.openingHours.toHour * 3600 + this.openingHours.toMinute * 60;
        const secondInTheDay = date.getHours() * 3600 + date.getMinutes() * 60;
        return (fromSeconds <= secondInTheDay && secondInTheDay <= toSeconds);
    }
}
