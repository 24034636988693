export class Reservation {
    constructor(customerid, fromDate, toDate, numberPersons, location) {
        this.arrived = false;
        this.customerid = customerid;
        this.__fromTimestamp = fromDate.getTime();
        this.__toTimestamp = toDate.getTime();
        this.__creationTimestamp = (new Date()).getTime();
        this.numberPersons = numberPersons;
        this.location = location;
        this.licenseplate = null;
        this.spot = null;
        this.spotFixed = false;
        this.accepted = false;
        this.notes = '';
        this.comment = '';
        this.arrived = false;
    }
    get fromDate() {
        return new Date(this.__fromTimestamp);
    }
    get fromTimestamp() {
        return this.__fromTimestamp;
    }
    get toDate() {
        return new Date(this.__toTimestamp);
    }
    get toTimestamp() {
        return this.__toTimestamp;
    }
    get creationDate() {
        return new Date(this.__creationTimestamp);
    }
    get creationTimestamp() {
        return this.__creationTimestamp;
    }
    load(data) {
        if (typeof data !== 'object')
            return;
        if (typeof data.customerid === 'string')
            this.customerid = data.customerid;
        if (typeof data.location === 'string')
            this.location = data.location;
        if (typeof data.numberPersons === 'number')
            this.numberPersons = data.numberPersons;
        if (data.fromDate !== undefined)
            this.__fromTimestamp = data.fromDate.seconds * 1000;
        if (data.toDate !== undefined)
            this.__toTimestamp = data.toDate.seconds * 1000;
        if (data.creationDate !== undefined)
            this.__creationTimestamp = data.creationDate.seconds * 1000;
        if (data.spot !== undefined)
            this.spot = data.spot;
        if (typeof data.spotFixed === 'boolean')
            this.spotFixed = data.spotFixed;
        if (typeof data.accepted === 'boolean')
            this.accepted = data.accepted;
        if (typeof data.licenseplate !== undefined)
            this.licenseplate = data.licenseplate;
        if (typeof data.notes === 'string')
            this.notes = data.notes;
        if (typeof data.comment === 'string')
            this.comment = data.comment;
        if (typeof data.arrived === 'boolean')
            this.arrived = data.arrived;
    }
}
