const sameDay = (date1, date2) => {
    if (date1.getFullYear() !== date2.getFullYear())
        return false;
    if (date1.getMonth() !== date2.getMonth())
        return false;
    if (date1.getDate() !== date2.getDate())
        return false;
    return true;
};
const multidayToSingleDays = (from, to) => {
    if (to < from) {
        // TODO write test for this
        throw new Error('End date cannot be before start date');
    }
    const days = [];
    let startDate = from;
    while (!sameDay(startDate, to)) {
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        endDate.setHours(endDate.getHours() + 24);
        days.push({
            from: startDate,
            to: endDate
        });
        startDate = endDate;
    }
    days.push({
        from: startDate,
        to
    });
    return days;
};
export { multidayToSingleDays };
