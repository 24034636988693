import { createDirectStore } from 'direct-vuex';
import clock from './modules/clock';
import locations from './modules/locations';
import auth from './modules/auth';
import customers from './modules/customers';
import reservations from './modules/reservations';
import products from './modules/products';
import takeaway from './modules/takeaway';
import daysummaries from './modules/daysummaries';
import mail from './modules/mail';
import debug from './modules/debug';
import StoreHelper from './storehelper';
const indebug = false;
const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
    modules: {
        clock,
        locations,
        auth,
        customers,
        reservations,
        products,
        takeaway,
        mail,
        daysummaries,
        debug
    },
    strict: indebug
});
store.dispatch.auth.subscribe();
store.commit.clock.update();
const clockInterval = setInterval(() => {
    store.commit.clock.update();
}, 1000);
const stopClockInterval = () => {
    clearInterval(clockInterval);
};
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };
export default store;
const storehelper = new StoreHelper();
export { storehelper, stopClockInterval };
