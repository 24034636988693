import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db } from '@/classes/firebase';
import { Customer } from '@/types/customers';
import { CollectionNames } from '@/types/collectionnames';
const customersModule = defineModule({
    namespaced: true,
    state: {
        customers: {}
    },
    getters: {},
    actions: {
        async subscribe(context) {
            const { commit, rootGetters } = customersActionContext(context);
            if (!rootGetters.auth.isSuperUser)
                throw new Error('Cannot subscribe to customers if you are not a superuser');
            if (commit.update === undefined)
                return;
            const subscriptionKey = 'customers';
            const snapper = {
                query: db.collection(CollectionNames.CUSTOMERS),
                mutation: commit.update,
                type: FirestoreSnapperType.Query
            };
            return await storehelper.subscribe(subscriptionKey, snapper);
        },
        async unsubscribe(context) {
            const { rootGetters } = customersActionContext(context);
            if (!rootGetters.auth.isSuperUser)
                throw new Error('Cannot unsubscribe to customers if you are not a superuser');
            storehelper.unsubscribe('customers');
        },
        async add(context, customer) {
            const { rootGetters } = customersActionContext(context);
            if (!rootGetters.auth.isSuperUser)
                throw new Error('Cannot add customers if you are not a superuser');
            const doc = await db.collection(CollectionNames.CUSTOMERS).add(customer.dump());
            return doc.id;
        },
        async update(_context, params) {
            await db.collection(CollectionNames.CUSTOMERS).doc(params.id).set(Object.assign({}, params.update), { merge: true });
        }
    },
    mutations: {
        update(state, snap) {
            const foundKeys = [];
            snap.docs.forEach(doc => {
                foundKeys.push(doc.id);
                state.customers[doc.id] = new Customer(doc.data());
            });
            const existingKeys = Object.keys(state.customers);
            existingKeys.forEach(key => {
                if (!foundKeys.includes(key)) {
                    delete state.customers[key];
                }
            });
        }
    }
});
export default customersModule;
export const customersGetterContext = (args) => moduleGetterContext(args, customersModule);
export const customersActionContext = (context) => moduleActionContext(context, customersModule);
