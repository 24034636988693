import { defineModule } from 'direct-vuex';
import { FirestoreSnapperType } from '../types';
import store, { moduleActionContext, moduleGetterContext, storehelper } from '..';
import { db } from '@/classes/firebase';
import { CollectionNames } from '@/types/collectionnames';
import { Product, ProductGroup } from '@/types/products';
import { pickBy } from 'lodash';
const SUBSCRIPTION_KEY = 'products';
const SUBSCRIPTION_KEY_GROUPS = 'productgroups';
const makeQuery = async (db) => {
    return await db.collection(CollectionNames.PRODUCTS).where('location', '==', store.state.locations.currentLocationId);
};
const makeGroupQuery = async (db) => {
    const location = store.state.locations.currentLocationId;
    return await db.collection(CollectionNames.PRODUCTGROUPS).where('location', '==', location);
};
const productsModule = defineModule({
    namespaced: true,
    state: {
        products: {},
        productGroups: {}
    },
    getters: {
        productByGroup(state) {
            const productsByGroup = {};
            const groups = new Set();
            Object.values(state.products).forEach(product => groups.add(product.group));
            groups.forEach(group => {
                productsByGroup[group] = pickBy(state.products, product => product.group === group);
            });
            return productsByGroup;
        }
    },
    actions: {
        async subscribe(context) {
            const { commit } = productsActionContext(context);
            if (commit.update === undefined)
                return;
            const snapper = {
                query: await makeQuery(db),
                mutation: commit.update,
                type: FirestoreSnapperType.Query
            };
            await storehelper.subscribe(SUBSCRIPTION_KEY, snapper);
            const snapper2 = {
                query: await makeGroupQuery(db),
                mutation: commit.updateGroups,
                type: FirestoreSnapperType.Query
            };
            await storehelper.subscribe(SUBSCRIPTION_KEY_GROUPS, snapper2);
        },
        unsubscribe(_context) {
            storehelper.unsubscribe(SUBSCRIPTION_KEY);
            storehelper.unsubscribe(SUBSCRIPTION_KEY_GROUPS);
        },
        async refreshQuery(_context) {
            const query = await makeQuery(db);
            if (storehelper.exists(SUBSCRIPTION_KEY)) {
                storehelper.updateQuery(SUBSCRIPTION_KEY, query);
            }
            const groupQuery = await makeGroupQuery(db);
            if (storehelper.exists(SUBSCRIPTION_KEY_GROUPS)) {
                storehelper.updateQuery(SUBSCRIPTION_KEY_GROUPS, groupQuery);
            }
        },
        async add(_context, product) {
            if (product.location !== store.state.locations.currentLocationId) {
                throw new Error('Cannot add a product in a different location');
            }
            const doc = await db.collection(CollectionNames.PRODUCTS).add({ ...product });
            return doc.id;
        },
        async delete(_context, productId) {
            await db.collection(CollectionNames.PRODUCTS).doc(productId).delete();
        },
        async update(context, { id, update }) {
            const { state } = productsActionContext(context);
            if (state.products[id] === undefined)
                throw new Error('Product does not exists');
            await db.collection(CollectionNames.PRODUCTS).doc(id).set(Object.assign({}, update), { merge: true });
        }
    },
    mutations: {
        update(state, snap) {
            state.products = {};
            snap.docs.forEach(doc => {
                const data = doc.data();
                state.products[doc.id] = new Product(data.name, data.price, data.location, data.description, data.group, data.kitchenname);
            });
        },
        updateGroups(state, snap) {
            state.productGroups = {};
            snap.docs.forEach(doc => {
                const group = new ProductGroup();
                const data = doc.data();
                group.load(data);
                state.productGroups[doc.id] = group;
            });
        }
    }
});
export default productsModule;
export const productsGetterContext = (args) => moduleGetterContext(args, productsModule);
export const productsActionContext = (context) => moduleActionContext(context, productsModule);
