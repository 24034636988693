export class ReservationSummary {
    constructor(from, to, customerId) {
        this.from = from;
        this.to = to;
        this.customerId = customerId;
    }
    load(data) {
        if (typeof data !== 'object')
            throw new Error('ReservationSummary: data is not an object');
        if (data.from !== undefined)
            this.from = new Date(data.from.seconds * 1000);
        if (data.to !== undefined)
            this.to = new Date(data.to.seconds * 1000);
        if (typeof data.customerId === 'string')
            this.customerId = data.customerId;
    }
    export() {
        return {
            ...this
        };
    }
}
