import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/reservation-overview',
        name: 'Reservaties',
        component: () => import(/* webpackChunkName: "reservations" */ '../views/ReservationOverview.vue')
    },
    {
        path: '/products-overview',
        name: 'Gerechten',
        component: () => import(/* webpackChunkName: "products" */ '../views/ProductsOverview.vue')
    },
    {
        path: '/takeaway-overview',
        name: 'Take-away',
        component: () => import(/* webpackChunkName: "takeaway" */ '../views/TakeAwayOverview.vue')
    },
    {
        path: '/customers',
        name: 'Klanten',
        component: () => import(/* webpackChunkName: "customers" */ '../views/CustomersOverview.vue')
    },
    {
        path: '/search',
        name: 'Zoeken',
        component: () => import(/* webpackChunkName: "customers" */ '../views/Customer.vue')
    },
    {
        path: '/days-overview',
        name: 'Days Overview',
        component: () => import(/* webpackChunkName: "days" */ '../components/Calendar')
    },
    {
        path: '/make-reservation',
        name: 'Make Reservation',
        component: () => import(/* webpackChunkName: "make-reservation" */ '../views/MakeReservation.vue')
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes
});
trackRouter(router);
export default router;
