import { defineModule } from 'direct-vuex';
import { moduleActionContext, moduleGetterContext } from '..';
const mailModule = defineModule({
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        sendMail(_context, email) {
            const link = document.createElement('a');
            const mailtoText = 'mailto:' + email.destination.join(',') + '?subject' + email.subject + '&body=' + email.body;
            link.setAttribute('href', mailtoText);
            link.setAttribute('target', '_blank');
            link.click();
        }
    },
    mutations: {}
});
export default mailModule;
export const mailGetterContext = (args) => moduleGetterContext(args, mailModule);
export const mailActionContext = (context) => moduleActionContext(context, mailModule);
